import "./styles.css";
import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactPlayer from "react-player";
import Vid1 from "./videos/1 - Email Generation.mp4";
import Vid2 from "./videos/2 - Stock Images.mp4";
import Vid3 from "./videos/3 - Fix Text.mp4";
import Vid4 from "./videos/4 - Image generation.mp4";
import Vid5 from "./videos/5 - Subject Line.mp4";
import Vid52 from "./videos/52 - MobilytixDempMp4.mp4";
import Vid6 from "./videos/6 - Chatbot.mp4";
import Vid7 from "./videos/7- Whatsapp Template.mp4";
import p1 from "./thumbs/p1.png";
import p2 from "./thumbs/p2.png";
import p3 from "./thumbs/p3.png";
import p4 from "./thumbs/p4.png";
import p5 from "./thumbs/p5.png";
import p52 from "./thumbs/p52.png";
import p6 from "./thumbs/p6.png";
import p7 from "./thumbs/p7.png";
import IconButton from "@mui/material/IconButton";
import { SvgIcon } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";


import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';


const data = [
  {
    feature: "Email Generation",
    hashLink: "email-gen",
    video: Vid1,
    description:
      "Select the segment, offer and the AI will generate the email for you",
    preview: p1,
    details: `Demo : Personalized email generation
                Segment: Customers of Mocktel telecom, age group :15 to 25, who use gaming applications, consume high speed data, large downloads, and live streaming, requires low-latency connections.
                Offer to push / Next Best offer:
                10gb data add-on valid 2 days for $9
                Mocktel Mobile recharge offer:  Low data alert! Top-up before the 'pay as you go' (payg) rates apply!  Enjoy uninterrupted high speed gaming sessions with this data top-up.
    `,
  },
  {
    feature: "Image Generation",
    hashLink: "image-gen",
    video: Vid4,
    description: "Translate your thoughts into images, assisted by AI",
    preview: p4,
    details: `Harness the power of artificial intelligence to seamlessly transform your abstract ideas and musings into vivid, captivating visual representations. Let the synergy of technology and creativity breathe life into your thoughts, painting a rich tapestry of images that resonate with the essence of your imagination.`,
  },
  {
    feature: "Stock Images",
    hashLink: "stock-images",
    video: Vid2,
    description:
      "Pick from a library of stock images, assisted by AI to match the email content",
    preview: p2,
    details: `Utilize our cutting-edge AI-powered image search functionality to browse through an extensive database of millions of high-quality stock images seamlessly. This intuitive tool is designed to intelligently analyze the content of your email and suggest images that perfectly complement your message. Whether you require eye-catching illustrations, versatile vectors, or captivating photographs, our email editor empowers you to access and incorporate these visuals directly within your email composition process. This streamlined feature not only saves you valuable time but also ensures that your emails are visually appealing and convey your message effectively by effortlessly integrating relevant images into your content.`,
  },
  {
    feature: "Content Assist",
    hashLink: "content-assist",
    video: Vid3,
    description:
      "Content Assist to fix grammar and spelling mistakes, to make text more friendly or more formal, to make text shorter or longer",
    preview: p3,
    details: `Content Assist is a versatile tool designed to enhance written content by correcting grammar and spelling errors, ensuring a polished and professional tone. It adapts text to suit various contexts, making it either friendlier or more formal, depending on your needs. Additionally, Content Assist offers the option to condense or extend text to meet specific length requirements, making it an invaluable resource for writers, students, and professionals alike. Whether you aim to refine your communication, adhere to specific style guidelines, or meet word count targets, Content Assist empowers you to craft precise and effective messages, documents, and articles with ease.`,
  },
  {
    feature: "Subject Line Generation",
    hashLink: "subject-gen",
    video: Vid5,
    description:
      "Curate impactful subject lines, assisted by AI, to ensure high open rates",
    preview: p5,
    details: `Leverage the power of artificial intelligence to meticulously curate engaging and attention-grabbing subject lines that guarantee exceptional open rates. With the assistance of cutting-edge AI technology, you can craft subject lines that leave a lasting impact, enticing your audience to eagerly open your emails. Maximize the effectiveness of your communication strategy and elevate your email marketing game to new heights by harnessing the potential of AI-driven subject line optimization.`,
  },
  {
    feature: "Email Engagement Rate Prediction",
    hashLink: "engagement-prediction",
    video: Vid52,
    description:
      "Assess the engagement rate of your email, assisted by AI, to ensure high open rates, also accompanied by human-like review and suggestions to improve the subject line",
    preview: p52,
    details: `Predict the success of your email, and finetune the subject line to enhance the open rate. Our cutting-edge AI technology is designed to analyze your email and predict its engagement rate, allowing you to optimize your subject line to maximize open rates. Additionally, our AI-powered solution offers human-like suggestions to improve your subject line, ensuring that your emails are engaging and effective.`,

  },
  {
    feature: "AI Chatbot",
    hashLink: "chatbot",
    video: Vid6,
    description:
      "AI chatbot to engage with customers on your website, trained on your data",
    preview: p6,
    details: `An advanced AI chatbot tailored specifically to your business, seamlessly engaging with visitors on your website. This cutting-edge chatbot is meticulously trained using your unique dataset, ensuring a personalized and efficient interaction with your customers. Harness the power of AI-driven conversations to enhance user experiences, provide instant support, and drive conversions, all while maintaining a consistent brand identity and customer satisfaction.
    Demo : In this example, you see a chatbot trained on Mahindra 6060 Tractor Brochure from https://media.mahindraauto.com.au/wp-content/uploads/2018/10/23141555/Mahindra-6060-6075-Tractor1.pdf
    `,
  },
  {
    feature: "Whatsapp Template Generation",
    hashLink: "whatsapp-gen",
    video: Vid7,
    description:
      "AI driven Whatsapp template to engage with customers on Whatsapp, trained on your data",
    preview: p7,
    details: `Leverage the power of cutting-edge AI technology with our custom WhatsApp template, meticulously crafted to foster dynamic customer engagement. Our AI-driven solution is meticulously trained on your specific data, ensuring personalized interactions that resonate with your audience.`,
  },
];

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
export default function App() {
  const [password, setPassword] = useState("");
  const [showOverlay, setShowOverlay] = useState(true);

  // Function to open the overlay
  const openOverlay = () => {
    setShowOverlay(true);
  };

  // Function to close the overlay
  const closeOverlay = () => {
    setShowOverlay(false);
  };



  useEffect(() => {

    //Check if current domain is mobilytix-ai.com. If not redirect to mobilytix-ai.com. Keep the check case insensitive
    const currentDomain = window.location.hostname;
    if (currentDomain.toLowerCase() !== "mobilytix-ai.com" && currentDomain.toLowerCase() !== "localhost") {
      window.location.href = "https://mobilytix-ai.com";
    }

    const passwordCookie = getCookie("demoPassword");
    if (passwordCookie === "mdm") {
      setShowOverlay(false);
    }
  }, []);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (password === "mdm") {
      const now = new Date();
      now.setTime(now.getTime() + 120 * 60 * 1000); // 120 minutes
      document.cookie = `demoPassword=mdm; expires=${now.toUTCString()}`;
      setShowOverlay(false);
    }
  };

  return (
    <>
      <CssBaseline />
      {showOverlay && (
        <Modal
          open={showOverlay}
          // Do not allow the modal to be closed when displayed due to a password
          disableEscapeKeyDown={password !== ""}
          disableBackdropClick={password !== ""}
        >
          <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)', height: '100%', width: '100%' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                minWidth: 300,
                borderRadius: 4,
              }}
            >
              <Typography variant="h5" id="password-modal-title" gutterBottom>
                Enter the password to view the demo
              </Typography>
              <form onSubmit={handlePasswordSubmit}> {/* Wrap the input and button in a form */}
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Button
                  type="submit" // Use type="submit" to enable Enter key submission
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Submit
                </Button>
              </form>
              <Typography id="password-modal-description" color="text.secondary" sx={{ mt: 2 }}>
                If you don't have the password, and interested in the demo, reach out to &nbsp;
                <Typography color="primary" component="span">
                  Kushambi (kushambi@comviva.com) and Rahul Valakonda (rahul.valakonda@comviva.com)
                </Typography>
                for the demo.
              </Typography>
            </Box>
          </div>
        </Modal>
      )}
      <AppBar position="relative">
        <Toolbar style={{ justifyContent: "center" }}>
          <Typography variant="h4" color="inherit" noWrap style={{ textAlign: "center" }}>
            MobiLytix Digital Marketing AI Features Demo
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 6,
          }}
        >
          {data.map((item) => (
            <Container
              maxWidth="lg"

              component="main"
              sx={{
                pt: 1,
                pb: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: 1,
                borderColor: 'grey.300',
                borderRadius: 3,
                mt: 1,
              }}
              key={item.feature} // Add a unique key for each item
            >
              {/* Invisible pixel for hashlink anchor */}
              <div id={item.hashLink} sx={{
                mt: 1
              }}></div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a href={`#${item.hashLink}`} style={{ textDecoration: 'none' }} >
                  {/* light grey anchor icon */}
                  <IconButton>
                    <SvgIcon style={{ color: 'lightgrey' }}>
                      <LinkIcon />
                    </SvgIcon>
                  </IconButton>
                </a>
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  {item.feature}
                </Typography>
              </div>

              <Typography variant="h5" align="center" color="text.secondary" paragraph >
                {item.description}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  maxWidth: '1000px', // Max width 800px
                  width: '100%', // Full width within the container
                }}
              >
                <div
                  style={{
                    height: '100%', // Let the video maintain its aspect ratio
                    maxWidth: '100%', // Ensure it doesn't overflow the container
                  }}
                >
                  <ReactPlayer
                    url={item.video}
                    controls={true}
                    loop={true}
                    playing={true}
                    muted={true}
                    playsinline={true}
                    width="100%"
                    height="100%" // Maintain aspect ratio
                    light={<img src={item.preview} alt="Preview" sx={{
                      '@media (max-width: 600px)': {
                        '& img': {
                          maxHeight: '300px', // Adjust the max height for mobile screens
                        },
                      },
                    }} />}
                    sx={{

                    }}
                  />
                </div>
              </div>
              <Box sx={{
                flexGrow: 1,
                mt: 1,
              }}
                className="pretext"
              >
                {item.details && <Typography variant="body1" align="left" color="text.secondary" paragraph sx={{
                  border: 1,
                  px: 1,
                  py: 1,
                  borderColor: 'grey.500',
                  borderRadius: 3,
                  color: 'black',
                }}>
                  {item.details}
                </Typography>}
              </Box>
            </Container>
          ))}
        </Box>
      </main>
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer" id="footerHash">
        {/* Link to footerHash */}
        <Copyright />
      </Box>
    </>
  );
}